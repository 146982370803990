

import {
  Component, Watch, Prop, Vue,
} from 'vue-property-decorator'
import { mapState } from 'vuex'
import store from '@/store'
import { l, localeFromPath, truncateText } from '@/utils/helpers'
import ResponsiveImage from '@/components/atoms/ResponsiveImage.vue'
import playIcon from '@/assets/svg/play.svg'

@Component({
  components: {
    ResponsiveImage,
    playIcon,
  },
  computed: mapState({
    films: (state: any) => state.content.films,
    isActive(state: any) {
      if (state.active.post.post.uuid === this.film.uuid) {
        return true
      }
      return false
    },
  }),
  methods: {
    l,
    truncateText,
  },
})
export default class FilmCard extends Vue {
  constructor() {
    super()
    this.locale = ''
  }

  @Prop() private film!: any

  private locale: string

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    this.locale = localeFromPath(this.$route.path || '/')
  }

  getLocale() {
    return this.locale
  }
}
