

import {
  Component, Vue,
} from 'vue-property-decorator'
import _ from 'lodash'
import { Carousel, Slide } from 'vue-carousel'
import { mapState } from 'vuex'
import FilmCard from '@/components/atoms/FilmCard.vue'

@Component({
  components: {
    FilmCard,
    Carousel,
    Slide,
  },
  computed: mapState({
    films: (state: any) => state.active.post.films,
  }),
})
export default class FilmCarousel extends Vue {
}
