

import {
  Component, Vue,
} from 'vue-property-decorator'
import _ from 'lodash'
import { Carousel, Slide } from 'vue-carousel'
import { mapState } from 'vuex'
import FilmCollectionCard from '@/components/atoms/FilmCollectionCard.vue'

@Component({
  components: {
    FilmCollectionCard,
    Carousel,
    Slide,
  },
  computed: mapState({
    filmCollections: (state: any) => state.content.filmCollections,
  }),
})
export default class FilmCollectionsCarousel extends Vue {
}
