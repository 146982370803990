
import {
  Component, Watch, Prop, Vue,
} from 'vue-property-decorator'
import { mapState } from 'vuex'
import _ from 'lodash'

import store from '@/store'
import { l, localeFromPath, formatDate } from '@/utils/helpers'

import FourOhFour from '@/components/molecules/FourOhFour.vue'
import Hero from '@/components/molecules/Hero.vue'
import MoviePlayer from '@/components/atoms/MoviePlayer.vue'
import FilmCollectionsCarousel from '@/components/molecules/FilmCollectionsCarousel.vue'
import FilmsCarousel from '@/components/molecules/FilmsCarousel.vue'
import Button from '@/components/atoms/Button.vue'
import Date from '@/components/atoms/Date.vue'
import ContentSection from '@/components/organisms/ContentSection.vue'
import SocialShare from '@/components/molecules/SocialShare.vue'

import conf from '../../configuration'

@Component({
  components: {
    FourOhFour,
    Hero,
    MoviePlayer,
    FilmCollectionsCarousel,
    FilmsCarousel,
    Button,
    ContentSection,
    Date,
    SocialShare,
  },
  // This is used by the Vue Meta package
  // to add dynamic meta information to HTML head
  // See also public/index.html
  metaInfo() {
    const {
      title, description, locale, imageAlt, thumbnail,
    } = this.$store.getters.activePost
    return {
      title,
      meta: [
        { name: 'description', content: description },

        // Open graph
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        {
          property: 'og:image',
          content: `${conf.media.baseUrl}${conf.media.sourcePath}/${conf.media.namespace}/${_.startsWith(thumbnail, '/') ? thumbnail : `/${thumbnail}`}`,
        },
        {
          property: 'og:image:alt',
          content: imageAlt,
        },

        // Twitter
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:summary', content: description },
        { property: 'twitter:title', content: title },
        { property: 'twitter:description', content: description },
        { property: 'twitter:url', content: window.location.href },
        {
          property: 'twitter:image',
          content: `${conf.media.baseUrl}${conf.media.sourcePath}/${conf.media.namespace}/${_.startsWith(thumbnail, '/') ? thumbnail : `/${thumbnail}`}`,
        },
      ],
      htmlAttrs: {
        lang: locale,
      },
      // Remove the /en/ part from the canonical URL if the language is English
      // Make sure canonical URLs always end with a /
      link: [
        {
          rel: 'canonical',
          href: locale === 'en' && !this.$route.path.includes('/en/')
            ? `${conf.url}${_.replace(_.endsWith(this.$route.path, '/') ? this.$route.path : `${this.$route.path}/`, '/', '/en/')}`
            : `${conf.url}${_.endsWith(this.$route.path, '/') ? this.$route.path : `${this.$route.path}/`}`,
        },
      ],
    }
  },
  computed: mapState({
    contentNotFound: (state: any) => (
      state.status === 404
    ),
    film: (state: any) => (
      (state.active && state.active.post) ? state.active.post.post : undefined
    ),
    videos: (state: any) => (
      (state.active
        && state.active.post
        && state.active.post.post
        && !_.isEmpty(state.active.post.post.videos)
        && state.active.post.post.contentType === 'film')
        ? _.flatten(_.map(state.active.post.post.videos, (v: any) => _.values(v)))
        : undefined
    ),
    films: (state: any) => (
      (state.active
        && state.active.post
        && !_.isEmpty(state.active.post.films))
        ? state.active.post.films
        : undefined
    ),
  }),
  methods: {
    l,
    formatDate,
    handleOnEmbedClick() {
      // console.log('Embed button clicked')
    },
    getRelatedContentIds(post:any) {
      if (post) {
        return _.compact(
          _.concat(
            post.relatedContent,
            post.relatedFilms,
            post.relatedDatabases,
            post.relatedTools,
            post.relatedInterviews,
            post.relatedGuides,
            post.relatedCurricula,
          ),
        )
      }
      return undefined
    },
  },
})
export default class Film extends Vue {
  constructor() {
    super()
    this.locale = ''
    this.displayDownloads = false
  }

  private locale: string

  private displayDownloads: boolean

  toggleDownloads() {
    this.displayDownloads = !this.displayDownloads
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    this.locale = localeFromPath(this.$route.path || '/')

    // Get all Film Collections
    if (_.isEmpty(this.$store.getters.filmCollections)) {
      try {
        store.dispatch('fetch', `/?contentType=filmCollection&&locale=${this.locale}`)
          .then((result: any) => {
            try {
              store.dispatch('set', { key: 'content.filmCollections', data: result })
            } catch (err) {
              document.dispatchEvent(new Event('custom-render-trigger'))
            }
          }).catch(
            (error: string) => {
              document.dispatchEvent(new Event('custom-render-trigger'))
              throw new Error(error)
            },
          )
      } catch (err) {
        document.dispatchEvent(new Event('custom-render-trigger'))
      }
    }

    if (this.$route && this.$route.params && this.$route.params.slug) {
      try {
        // Fetch active Film or FilmCollection.
        // This is the content which is rendered trough this template.
        store.dispatch('fetch', `/?slug=${this.$route.params.slug}&&locale=${this.locale}`)
          .then((result: any) => {
            if (result && result[0]) {
              // Set the Film or FilmCollection content as active film in the VUEX store
              try {
                store.dispatch('set', { key: 'active.post.post', data: result[0] })

                let filmCollectionUuid:string = ''
                // If this is a Film Collection...
                if (result[0].contentType === 'filmCollection') {
                  // Set Film Collection active
                  try {
                    store.dispatch('set', { key: 'active.filmCollectionUuid', data: result[0].uuid })
                    filmCollectionUuid = result[0].uuid
                  } catch (err) {
                    document.dispatchEvent(new Event('custom-render-trigger'))
                  }
                } else {
                  // If this is a Film...
                  // Set Film Collection active
                  try {
                    store.dispatch('set', { key: 'active.filmCollectionUuid', data: result[0].filmCollection })
                  } catch (err) {
                    document.dispatchEvent(new Event('custom-render-trigger'))
                  }
                  // Set Film active
                  try {
                    store.dispatch('set', { key: 'active.filmUuid', data: result[0].uuid })
                    filmCollectionUuid = result[0].filmCollection
                  } catch (err) {
                    document.dispatchEvent(new Event('custom-render-trigger'))
                  }
                }

                // Reset active Films
                store.dispatch('set', { key: 'active.post.films', data: [] })
                try {
                  // Fetch all Films associated with this Film Collection
                  store.dispatch(
                    'fetch',
                    `/?contentType=film&&locale=${this.locale}&&filmCollection=${filmCollectionUuid}`,
                  ).then((films: any) => {
                    if (films) {
                      try {
                        // Set all Films for this Collection as active in the store
                        store.dispatch('set', { key: 'active.post.films', data: films })

                        /* Tell the prerender-spa-plugin that data fetching is complete
                        * and that prerendering can start, see vue.config.js.
                        * Wait until all the stores variables are set and give
                        * the render engine some time to render.
                        */
                        if (this.$store.state
                            && this.$store.state.active
                            && this.$store.state.active.filmCollectionUuid
                            && this.$store.state.active.post
                            && this.$store.state.active.post.films
                            && this.$store.state.active.post.post) {
                          setTimeout(() => document.dispatchEvent(new Event('custom-render-trigger')), 6000)
                        }
                      } catch (err) {
                        document.dispatchEvent(new Event('custom-render-trigger'))
                      }
                    }
                  })
                    .catch(
                      (error: string) => {
                        document.dispatchEvent(new Event('custom-render-trigger'))
                      },
                    )
                } catch (err) {
                  document.dispatchEvent(new Event('custom-render-trigger'))
                }
              } catch (err) {
                document.dispatchEvent(new Event('custom-render-trigger'))
              }
            }
          })
          .catch(
            (error: string) => {
              try {
                store.dispatch('set', { key: 'status', data: 404 })
              } catch (err) {
                document.dispatchEvent(new Event('custom-render-trigger'))
              }
              setTimeout(() => document.dispatchEvent(new Event('custom-render-trigger')), 3000)
            },
          )
      } catch (err) {
        document.dispatchEvent(new Event('custom-render-trigger'))
      }
    }
  }
}
