

import {
  Component, Watch, Prop, Vue,
} from 'vue-property-decorator'
import { mapState } from 'vuex'
import store from '@/store'
import { l, localeFromPath, truncateText } from '@/utils/helpers'

@Component({
  computed: mapState({
    films: (state: any) => state.content.films,
    isActive(state: any) {
      if (state.active.post.post.uuid === this.filmCollection.uuid
        || state.active.post.post.filmCollection === this.filmCollection.uuid) {
        return true
      }
      return false
    },
  }),
  methods: {
    l,
    truncateText,
  },
})
export default class FilmCollectionCard extends Vue {
  constructor() {
    super()
    this.locale = ''
  }

  @Prop() private filmCollection!: any

  private locale: string

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    this.locale = localeFromPath(this.$route.path || '/')
  }

  getLocale() {
    return this.locale
  }
}
